import React, { useRef } from 'react';
import HeroSection from '../components/HeroSection';
import BundleOverview from '../components/BundleOverview';
import BuyerRights from '../components/BuyerRights';
import BuyerBenefits from '../components/BuyerBenefits';
import CategorySection from '../components/CategorySection';
import CallToActionSection from '../components/CallToActionSection';
import FAQSection from '../components/FAQSection';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BackToTop from '../components/BackToTop';

const LandingPage = () => {
    const fullTitles = [
        '🎥 5,000+ Professional Overlays 🎨',
        '🎞️ 8,000 Final Cut Pro Elements 🎬',
        ' 5,000+ Motion Graphics Templates 🌟',
        '🎬 5,000+ Adobe Premiere Pro Templates 🎥',
        '🎥 5,000+ Adobe After Effects Templates 🌟',
        '🎬 2,000+ Premiere Pro Presets 🎥',
        '👕 Ultimate T-Shirt Design Collection 🎨',
        '🎨 Ultimate SVG Elements Collection ✂️',
        '☕ Premium Mug Design Collection 🎨',
        '🔖 10,000+ Icons Collection 🖼️',
        '🖼️ 2,500+ PNG Clipart Collection 🎨',
        '📚 1,000+ Graphics Library 🎨',
        '📊 Mega Microsoft PowerPoint Templates 🎨',
        '📊 5,000+ Infographics Collection 🎨',
        '💍 1,200+ Wedding Album Templates 🎨',
        '📄 500+ Professional Resume Templates 🎨',
        '🔤 Mega Fonts Collection 🎨',
        '🎨 15,000+ Photoshop Actions Collection 🎥',
        '🎨 5,000 Adobe Illustrator Templates 🖌️',
        '🎨 5,000 CorelDraw Templates 🖌️',
    ];

    const simplifiedTitles = [
        'Professional Overlays',
        'Final Cut Pro',
        'Motion Graphics',
        'Adobe Premiere Pro',
        'Adobe After Effects',
        'Premiere Pro Presets',
        'Ultimate T-Shirt Design',
        'Ultimate SVG Elements',
        'Premium Mug Design',
        'Icons Collection',
        'PNG Clipart',
        'Graphics Library',
        'PowerPoint Templates',
        'Infographics',
        'Wedding Album',
        'Resume Templates',
        'Fonts Collection',
        'Photoshop Action',
        'Adobe Illustrator Templates',
        'CorelDraw Templates',
    ];

    // Create refs
    const scrollRefs = useRef(
        fullTitles.reduce((refs, title) => {
            refs[title] = React.createRef();
            return refs;
        }, {})
    );

    // Map simplified titles to full titles
    const titleMap = simplifiedTitles.reduce((map, title, index) => {
        map[title] = fullTitles[index];
        return map;
    }, {});

    // Scroll handler
    const scrollToSection = (simplifiedTitle) => {
        const fullTitle = titleMap[simplifiedTitle];
        if (fullTitle && scrollRefs.current[fullTitle]?.current) {
            scrollRefs.current[fullTitle].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const onScrollHandlers = simplifiedTitles.reduce((handlers, title) => {
        handlers[title] = () => scrollToSection(title);
        return handlers;
    }, {});

    return (
        <div>
            <Header onScrollHandlers={onScrollHandlers} />
            <HeroSection />
            <BundleOverview />
            <BuyerRights />
            <BuyerBenefits />
            <CategorySection refs={scrollRefs.current} />
            <CallToActionSection />
            <FAQSection />
            <Footer />
            <BackToTop />
        </div>
    );
};

export default LandingPage;
