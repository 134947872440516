import React, { useEffect, useRef, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import MegaMenu from '../components/MegaMenu';
import logo from '../assets/sitelogo.png';

const Header = ({ onScrollHandlers }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For desktop dropdown
    const [isProductOverviewCollapsed, setIsProductOverviewCollapsed] = useState(false); // For mobile collapsible menu
    const [isSticky, setIsSticky] = useState(false); // Sticky state
    const [stickyVisible, setStickyVisible] = useState(false); // Controls smooth visibility of sticky header
    const [headerHeight, setHeaderHeight] = useState(0); // State for header height
    const headerRef = useRef(null); // Ref for the header
    const menuRef = useRef(null);
    const navigate = useNavigate(); // Handle Navigation
    let dropdownTimeout;

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const openDropdown = () => {
        if (dropdownTimeout) clearTimeout(dropdownTimeout); // Clear any existing timeout
        setIsDropdownOpen(true);
    };

    const closeDropdown = () => {
        dropdownTimeout = setTimeout(() => setIsDropdownOpen(false), 200); // Add delay before closing
    };

    // Close the menu when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false); // Close menu if clicking outside
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // Sticky Header Scroll Logic
    useEffect(() => {
        let lastScrollY = window.scrollY;
        const viewportHeight = window.innerHeight;

        // Set initial visibility
        if (lastScrollY === 0) {
            setStickyVisible(true);
            setIsSticky(false);
        }

        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY === 0) {
                // Always show header at the top of the page
                setStickyVisible(true);
                setIsSticky(false);
            } else if (currentScrollY > lastScrollY && currentScrollY > viewportHeight * 0.1) {
                // Scrolling down past 10% of the viewport height, hide the sticky header
                setStickyVisible(false);
            } else {
                // Scrolling up or staying in the top 10%, show the sticky header
                setStickyVisible(true);
                setIsSticky(true);
            }

            lastScrollY = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Calculate and set header height
    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    }, []);

    const handleScrollOrNavigate = (item) => {
        if (onScrollHandlers?.[item]) {
            // If onScrollHandlers is defined, invoke it
            onScrollHandlers[item]?.();
        } else {
            // Navigate to the landing page with state for scrolling
            navigate('/', { state: { scrollTo: item } });
        }
    };

    // MegaMenu categories (should be moved to a shared constants file for reuse)
    const megaMenuCategories = [
        {
            category: '🎥 Video and Motion Graphics',
            items: [
                'Professional Overlays',
                'Final Cut Pro',
                'Motion Graphics',
                'Adobe Premiere Pro',
                'Adobe After Effects',
                'Premiere Pro Presets',
            ],
        },
        {
            category: '🎨 Graphic Elements',
            items: [
                'Ultimate T-Shirt Design',
                'Ultimate SVG Elements',
                'Premium Mug Design',
                'Icons Collection',
                'PNG Clipart',
                'Graphics Library',
            ],
        },
        {
            category: '📝 Templates',
            items: [
                'PowerPoint Templates',
                'Infographics',
                'Wedding Album',
                'Resume Templates',
            ],
        },
        {
            category: '🖌️ Graphic Design Assets',
            items: [
                'Fonts Collection',
                'Photoshop Action',
                'Adobe Illustrator Templates',
                'CorelDraw Templates',
            ],
        },
    ];

    return (
        <>
            {/* Header */}
            <header
                ref={headerRef}
                className={`bg-gray-900 text-white lg:px-32 lg:py-2 px-2 py-2 flex justify-between items-center w-full transition-all duration-500 ease-in-out ${isSticky ? 'fixed top-0' : 'absolute'
                    } ${stickyVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'} z-50`}
            >
                <div className="flex items-center space-x-2">
                    {/* Hamburger Menu for Mobile */}
                    <div className="md:hidden ml-2" onClick={toggleMenu}>
                        {isOpen ? <FaTimes /> : <FaBars />}
                    </div>
                    <Link to="/" onClick={() => setIsOpen(false)}>
                        <img src={logo} alt="Site Logo" className="h-14 lg:h-24" />
                    </Link>

                    {/* Navigation for Desktop */}
                    <nav className={`hidden md:flex md:items-center md:space-x-10`}>
                        <ul className="flex space-x-10 ml-10">
                            <li>
                                <Link
                                    to="/"
                                    className="hover:text-blue-500 cursor-pointer text-white"
                                    onClick={toggleMenu}
                                >
                                    Home
                                </Link>
                            </li>
                            <li
                                className="relative group"
                                onMouseEnter={openDropdown}
                                onMouseLeave={closeDropdown}
                            >
                                <button className="hover:text-blue-500 cursor-pointer text-white">
                                    Product Overview
                                </button>
                                {isDropdownOpen && (
                                    <div className="absolute top-full left-0 z-40">
                                        <MegaMenu onScroll={(key) => handleScrollOrNavigate(key)} />
                                    </div>
                                )}
                            </li>
                            <li>
                                <Link
                                    to="/contact-us"
                                    className="hover:text-blue-500 cursor-pointer text-white"
                                    onClick={toggleMenu}
                                >
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            {/* Placeholder to prevent overlay */}
            <div
                style={{ height: `${headerHeight}px` }}
                className="bg-gray-900"
            />


            {/* Mobile Navigation */}
            <nav
                ref={menuRef}
                className={`fixed top-0 left-0 w-3/4 h-screen bg-gray-900 md:hidden transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
                    } z-50`}
            >
                <ul className="flex flex-col space-y-6 text-lg justify-start items-start pl-6 pt-10">
                    <li>
                        <Link
                            to="/"
                            className="hover:text-blue-500 cursor-pointer text-white"
                            onClick={toggleMenu}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <button
                            className="hover:text-blue-500 cursor-pointer text-white flex justify-between items-center w-full"
                            onClick={() =>
                                setIsProductOverviewCollapsed(!isProductOverviewCollapsed)
                            }
                        >
                            <span>Product Overview</span>
                            <span className={`${isProductOverviewCollapsed ? "" : "pl-24"}`}>
                                {isProductOverviewCollapsed ? "-" : "+"}
                            </span>
                        </button>
                        <div
                            className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${isProductOverviewCollapsed ? "max-h-[500px]" : "max-h-0"
                                }`}
                        >
                            <div className="pl-6 space-y-4 max-h-[50vh] overflow-y-auto">
                                {megaMenuCategories.map((category, index) => (
                                    <div key={index}>
                                        <h3 className="text-sm font-semibold text-gray-300 mb-2">
                                            {category.category}
                                        </h3>
                                        <ul className="space-y-1">
                                            {category.items.map((item, idx) => (
                                                <li key={idx}>
                                                    <button
                                                        className="hover:text-blue-500 cursor-pointer text-gray-400"
                                                        onClick={() => handleScrollOrNavigate(item)}
                                                    >
                                                        {item}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </li>
                    <li>
                        <Link
                            to="/contact-us"
                            className="hover:text-blue-500 cursor-pointer text-white"
                            onClick={toggleMenu}
                        >
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Header;