import React, { useState, useEffect } from 'react';
import arrow from '../assets/arrow.png'; // Import the PNG file

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Show button if scrolled down 50% of the viewport height
            if (window.scrollY > window.innerHeight * 0.5) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        isVisible && (
            <button
                onClick={scrollToTop}
                className="fixed bottom-5 right-5 z-50 shadow-lg hover:opacity-80"
                style={{
                    zIndex: 9999, // Ensures the button is above all other elements
                    width: '50px', // Adjust width as needed
                    height: '50px', // Adjust height as needed
                    backgroundImage: `url(${arrow})`, // Set the PNG as the background
                    backgroundSize: 'contain', // Ensure the image fits within the button
                    backgroundRepeat: 'no-repeat', // Prevent repeating
                    backgroundPosition: 'center', // Center the image
                    border: 'none', // Remove default button borders
                    cursor: 'pointer', // Add a pointer cursor
                }}
            ></button>
        )
    );
};

export default BackToTop;
