import React from 'react';
import SectionHeader from './common/SectionHeader';

// Import assets for categories
import overlay5000 from '../assets/categorySection/overlay5000.png';
import finalcut8000 from '../assets/categorySection/finalcut8000.png';
import motion5000gif from '../assets/categorySection/motion5000.gif';
import premiere5000 from '../assets/categorySection/adobepremiere5000.gif';
import aftereffect5000 from '../assets/categorySection/aftereffect5000.gif';
import premierepreset2000 from '../assets/categorySection/2000premierepreset.gif';
import millionshirt from '../assets/categorySection/millionshirt.png';
import ultimatesvg from '../assets/categorySection/ultimatesvg.png';
import mugdesign from '../assets/categorySection/mugdesign.png';
import icons10000 from '../assets/categorySection/10000icons.png';
import clipart from '../assets/categorySection/clipart.png';
import graphiclibrary from '../assets/categorySection/graphiclibrary.png';
import powerpoint from '../assets/categorySection/powerpoint.png';
import infographic from '../assets/categorySection/infographic.png';
import weddingalbum from '../assets/categorySection/weddingalbum.png';
import resumetemplate from '../assets/categorySection/resumetemplate.png';
import font from '../assets/categorySection/font.png';
import photoshopaction from '../assets/categorySection/photoshopaction.png';
import aitemplate from '../assets/categorySection/aitemplate.png';
import coreldraw from '../assets/categorySection/coreldraw.png';

// Define sub-category headers and assets
const categories = [
    {
        header: 'Video and Motion Graphics',
        items: [
            {
                title: '🎥 5,000+ Professional Overlays 🎨',
                description: `Transform your creative projects with over 5,000 high-quality overlays designed to enhance photos, videos, and digital designs effortlessly.`,
                details: [
                    `✨ <b>Photography:</b> Add stunning textures and visual flair to your photos 🌟. Achieve unique effects like vintage vibes 🎞️, grunge textures 🤘, and artistic light leaks 🌅 for truly captivating visuals.`,
                    `🎬 <b>Video Production:</b> Bring cinematic brilliance to your videos 🌠! Use overlays to include lens flares 🌟, film burns 🔥, and other dynamic effects, making your productions stand out with professional-grade visuals.`,
                    `🖌️ <b>Graphic Design:</b> Enrich your designs with overlays that add depth, texture, and accents 🎨. Whether you're creating social media posts 📱, website graphics 💻, or promotional content 📣, these overlays ensure your work shines brightly 🌟.`,
                ],
                image: overlay5000,
            },
            {
                title: '🎞️ 8,000 Final Cut Pro Elements 🎬',
                description: `Take your video editing to the next level with this extensive collection of Final Cut Pro elements. Designed for creators of all skill levels, these assets will help you craft professional and visually stunning projects effortlessly.`,
                details: [
                    `✨ <b>Transitions:</b> Seamlessly switch between scenes with a variety of dynamic transitions 🚀. From smooth fades to energetic zooms, keep your audience engaged every step of the way.`,
                    `🎬 <b>Titles and Text Effects:</b> Add captivating titles and text animations to your videos 🎥. Perfect for intros, lower thirds, or callouts, these elements give your videos a polished and professional look.`,
                    `🔥 <b>Special Effects:</b> Create dramatic impact with cinematic overlays, color effects, and visual enhancements 🌟. Whether you’re editing a vlog, short film, or corporate project, these elements will bring your vision to life.`,
                    `💻 <b>Ease of Use:</b> Compatible with Final Cut Pro, these elements are easy to customize and integrate into your workflow. Save time while achieving exceptional results 💡.`,
                ],
                image: finalcut8000,
            },
            {
                title: ' 5,000+ Motion Graphics Templates 🌟',
                description: `Elevate your video projects with a vast library of motion graphics templates designed to captivate your audience and streamline your creative process. Perfect for creators, editors, and marketers!`,
                details: [
                    `✨ <b>Dynamic Transitions:</b> Make every scene change seamless and stylish 🌈. From smooth fades to bold geometric wipes, keep your audience immersed in your story.`,
                    `🎬 <b>Intro & Outro Templates:</b> Leave a lasting impression with stunning intros 🚀 and polished outros 🏁. These templates are perfect for branding, product launches, and presentations.`,
                    `🔥 <b>Infographic Animations:</b> Present data in a visually engaging way 📊. Use animated graphs, charts, and icons to keep viewers focused and informed.`,
                    `🌠 <b>Customizable Effects:</b> Tailor each template to fit your style. Adjust colors, speeds, and layouts to match your brand or creative vision 🎨.`,
                    `💻 <b>Versatile Applications:</b> Ideal for YouTube videos, social media posts, promotional ads, or corporate presentations 🎥. These templates help you save time and deliver professional-quality results effortlessly.`,
                ],
                image: motion5000gif,
            },
            {
                title: '🎬 5,000+ Adobe Premiere Pro Templates 🎥',
                description: `Unlock your creative potential with an extensive collection of Premiere Pro templates, meticulously designed to enhance your video editing projects. Whether you're working on a cinematic masterpiece, a commercial, or social media content, this bundle has everything you need to elevate your work.`,
                details: [
                    `✨ <b>Project-Specific Templates:</b>`,
                    `<b>YouTube Templates:</b> Perfect for vloggers and creators looking to add dynamic visuals to their videos.`,
                    `<b>Wedding Templates:</b> Capture special moments with beautifully designed, romantic overlays and transitions 💍.`,
                    `<b>Real Estate Templates:</b> Showcase property listings with sleek animations and professional-grade graphics 🏠.`,
                    `<b>Social Media Templates:</b> Create eye-catching content tailored for platforms like Instagram, TikTok, and Facebook 📱.`,
                    `🔥 <b>Diverse Styles:</b> From vintage-inspired themes 🎞️ to modern, urban aesthetics 🌆, these templates suit a wide range of creative visions.`,
                    `💻 <b>Ease of Use:</b> Customizable templates ensure quick edits and professional results, saving you time and effort ⏳.`,
                    `🌟 <b>Specialized Effects:</b> Add dramatic trailer effects, smooth logo reveals, and engaging openers to keep your audience hooked 🎥.`,
                    `This bundle is a must-have for video editors who want to produce high-quality, impactful content across various industries.`,
                ],
                image: premiere5000,
            },
            {
                title: '🎥 5,000+ Adobe After Effects Templates 🌟',
                description: `Unleash the full potential of your video editing skills with this massive collection of Adobe After Effects templates. Whether you’re a professional editor, a social media creator, or a hobbyist, these templates provide the tools to bring your vision to life with ease and efficiency.`,
                details: [
                    `✨ <b>Diverse Project Themes:</b>`,
                    `<b>YouTube Templates:</b> Engage your audience with creative intros, lower thirds, and transitions tailored for content creators 📹.`,
                    `<b>Weddings:</b> Add elegance and sophistication to wedding videos with romantic titles, overlays, and cinematic effects 💍.`,
                    `<b>Promos & Ads:</b> Craft eye-catching promotional videos and advertisements with bold animations and professional-grade graphics 📢.`,
                    `<b>Slideshow Templates:</b> Showcase your photos and videos with beautifully animated slideshows, perfect for presentations or events 📸.`,
                    `🔥 <b>Creative Styles:</b> From vintage and retro themes 🎞️ to sleek, urban designs 🌆, find the perfect style for any project.`,
                    `🎬 <b>Professional Effects:</b>`,
                    `<li>Smooth transitions 🚀</li>`,
                    `<li>Eye-catching logo reveals 🌟</li>`,
                    `<li>Seamless overlays 🔥</li>`,
                    `💻 <b>Ease of Customization:</b>`,
                    `Modify colors, text, and elements to fit your project perfectly, saving time while achieving stunning results ⏳.`,
                    `This bundle is perfect for creating professional-quality videos, enhancing branding projects, and elevating your storytelling abilities.`,
                ],
                image: aftereffect5000,
            },
            {
                title: '🎬 2,000+ Premiere Pro Presets 🎥',
                description: `Streamline your video editing process and unlock new creative possibilities with over 2,000 expertly designed Premiere Pro presets. These presets are perfect for editors seeking professional-grade results in less time.`,
                details: [
                    `✨ <b>Cinematic Color Grading:</b> Achieve stunning color tones and cinematic vibes with just a few clicks 🎞️. From dramatic film looks to vibrant modern aesthetics, these presets enhance the mood and atmosphere of your videos.`,
                    `🔥 <b>Transitions:</b> Effortlessly switch between scenes with dynamic transitions 🚀. Choose from smooth fades, glitch effects, zooms, and more to keep your videos engaging.`,
                    `🌈 <b>Special Effects:</b> Add impactful effects like light leaks, motion blurs, and speed ramps for dramatic visual appeal 🌟.`,
                    `🎥 <b>Versatile Use Cases:</b> Perfect for YouTube videos 📹, wedding films 💍, social media content 📱, and promotional ads 📢. Tailor your edits for various styles, including urban, vintage, or sleek corporate aesthetics 🌆.`,
                    `💻 <b>Customizable and Easy to Use:</b> Save time with easy-to-apply presets while maintaining full control over adjustments. Perfect for editors of all skill levels, from beginners to professionals.`,
                    `This bundle is a must-have for anyone looking to elevate their Premiere Pro editing with efficiency and style.`,
                ],
                image: premierepreset2000,
            },
        ],
    },
    {
        header: 'Graphic Elements',
        items: [
            {
                title: '👕 Ultimate T-Shirt Design Collection 🎨',
                description: `Dive into the world of creativity with the Ultimate T-Shirt Design Collection, featuring over one million diverse and ready-to-use designs. Perfect for apparel businesses, print-on-demand sellers, and anyone looking to make a bold statement with custom T-shirts.`,
                details: [
                    `✨ <b>Extensive Categories:</b> <br>This collection covers everything from fitness to festive themes:`,
                    `<li>🎸 Music and Band Designs</li>`,
                    `<li>🐾 Animal & Pet Lovers Themes</li>`,
                    `<li>🎓 Graduation and Academic Milestones</li>`,
                    `<li>American Patriotism and Holidays</li>`,
                    `<li>🏍️ Motorsports & Vehicles</li> ...and so much more!`,
                    `🔥 <b>Perfect for Any Occasion:</b> From trendy, motivational slogans to niche topics like yoga, veganism, and skydiving, these designs are tailored to suit every audience.`,
                    `🎨 <b>Fully Editable and Customizable:</b> All files are organized and easy to modify, allowing you to adjust colors, fonts, and layouts to match your brand or personal style.`,
                    `💻 <b>Organized for Convenience:</b> With over 50 categories, every design is well-organized, making it simple to find exactly what you need.`,
                    `🚀 <b>Boost Your Sales:</b> Take your apparel business to the next level with unique, eye-catching T-shirt designs that are sure to stand out and attract attention.`,
                ],
                image: millionshirt,
            },
            {
                title: '🎨 Ultimate SVG Elements Collection ✂️',
                description: `Unlock endless creative potential with the Ultimate SVG Elements Collection, featuring thousands of high-quality SVG files designed for professional and personal projects. Whether you’re crafting for business or hobbies, this collection offers an extensive variety to suit every need.`,
                details: [
                    `✨ <b>Diverse Themes and Categories:</b> <br>This collection includes an incredible range of themes to match any project:`,
                    `<li>🌸 <b>Wedding & Celebrations:</b> Add elegance to invitations, decor, and gifts with wedding-themed SVGs.</li>`,
                    `<li>🥗 <b>Vegan & Eco-Friendly:</b> Promote sustainable living with creative and stylish eco-themed designs.</li>`,
                    `<li>🍷 <b>Wine & Whiskey Bundles:</b> Perfect for bar decor, personalized gifts, or event branding.</li>`,
                    `<li>🏈 <b>Veteran & Patriotic:</b> Celebrate patriotism with designs dedicated to veterans and national pride.</li>`,
                    `<li>🌿 <b>Botanical & Seasonal:</b> Tropic leaves, winter elements, vegetables, and more for seasonal and nature-inspired creations.</li>`,
                    `🔥 <b>Perfect for Any Project:</b> <br>From crafting unique T-shirts and home decor to creating standout digital designs, these SVGs are ideal for:`,
                    `<li>🎁 Personalized Gifts</li>`,
                    `<li>🖼️ Wall Art & Prints</li>`,
                    `<li>💻 Digital Marketing Assets</li>`,
                    `🖌️ <b>Compatible and Easy to Use:</b> <br>All SVG files are fully editable and compatible with popular design software and cutting machines like Cricut and Silhouette.`,
                    `📂 <b>Well-Organized Categories:</b> <br>Every file is sorted into easy-to-navigate folders, ensuring you can find the perfect design in seconds.`,
                    `🚀 <b>Boost Creativity:</b> <br>Whether you’re crafting for fun, business, or special events, this bundle provides the tools to make your ideas shine.`,
                ],
                image: ultimatesvg,
            },
            {
                title: '☕ Premium Mug Design Collection 🎨',
                description: `Unleash creativity with the Premium Mug Design Collection, featuring an extensive range of high-quality designs tailored for mug customization. Perfect for small businesses, personal gifts, or promotional products, this collection has everything you need to create standout mugs.`,
                details: [
                    `✨ <b>Diverse Themes & Styles:</b> <br>Whether it’s for coffee enthusiasts or special occasions, this collection includes:`,
                    `<li>🖋️ <b>Inspirational Quotes:</b> Beautifully styled text for motivational mugs.</li>`,
                    `<li>🎉 <b>Seasonal Designs:</b> Perfect for Christmas, Valentine’s Day, and more.</li>`,
                    `<li>🐾 <b>Fun Graphics:</b> Playful and creative visuals to brighten anyone’s day.</li>`,
                    `<li>🍵 <b>Minimalist Aesthetics:</b> Clean and modern designs for a chic touch.</li>`,
                    `🔥 <b>Ready-to-Use Files:</b> <br>All designs come in print-ready formats, making the production process effortless.`,
                    `🖌️ <b>Customizable for Your Needs:</b> <br>Easily modify text, colors, and layouts to match your brand or occasion using popular design software.`,
                    `📦 <b>Organized for Convenience:</b> <br>This collection is organized into sections (e.g., quotes, coffee themes, seasonal bundles) for quick and easy access to the perfect design.`,
                    `🚀 <b>Perfect for Business or Personal Projects:</b> <br>Whether you’re creating for an online store, corporate gifts, or family presents, this collection ensures your mugs stand out and leave a lasting impression.`,
                ],
                image: mugdesign,
            },
            {
                title: '🔖 10,000+ Icons Collection 🖼️',
                description: `Empower your designs with a comprehensive collection of over 10,000 high-quality icons. Whether for web design, app development, presentations, or branding, this versatile bundle covers all your icon needs with ease.`,
                details: [
                    `✨ <b>Extensive Format Compatibility:</b> <br>Each icon is available in multiple formats, including:`,
                    `<li><b>SVG:</b> Scalable and perfect for responsive web design.</li>`,
                    `<li><b>PNG:</b> Ready-to-use, high-quality raster files.</li>`,
                    `<li><b>PDF:</b> Ideal for documentation and print purposes.</li>`,
                    `<li><b>EPS:</b> Easily editable in vector design tools like Adobe Illustrator.</li>`,
                    `🌟 <b>Diverse Themes:</b> <br>The collection includes icons across various themes and categories:`,
                    `<li>📱 <b>Technology & Devices</b></li>`,
                    `<li>🌍 <b>Business & Global Concepts</b></li>`,
                    `<li>🏥 <b>Healthcare & Medical</b></li>`,
                    `<li>🎨 <b>Creative & Artistic Elements</b></li>`,
                    `<li>🛍️ <b>E-commerce & Shopping</b></li>`,
                    `🔥 <b>Perfect for Any Project:</b> <br>From website and mobile app designs to professional presentations and digital marketing campaigns, these icons offer endless creative applications.`,
                    `🎨 <b>Customizable for Personalization:</b> <br>Fully editable in popular design software, these icons allow you to adjust colors, sizes, and styles to perfectly match your project’s branding or theme.`,
                    `📂 <b>Organized for Convenience:</b> <br>All files are meticulously organized into folders by format and theme, ensuring you can quickly find and use the icons you need.`,
                    `🚀 <b>Professional Results in No Time:</b> <br>Whether you're designing for clients or creating personal projects, this icon collection will elevate your work and save you valuable time.`,
                ],
                image: icons10000,
            },
            {
                title: '🖼️ 2,500+ PNG Clipart Collection 🎨',
                description: `Explore your creativity with this vibrant collection of over 2,500 high-quality PNG clipart files. Perfect for crafting, digital designs, and marketing materials, this collection brings your ideas to life with stunning visuals and ready-to-use assets.`,
                details: [
                    `✨ Key Features:`,
                    `<li><b>Transparent Backgrounds:</b> Each clipart file features a transparent background, making it easy to integrate seamlessly into any design or project.</li>`,
                    `<li><b>High-Resolution Files:</b> Enjoy crisp, clear images suitable for both digital and print applications.`,
                    `Wide Variety of Themes:`,
                    `<li>🌸 Floral and Nature Elements</li>`,
                    `<li>🏰 Fairy Tale & Fantasy Art</li>`,
                    `<li>🍔 Food and Beverage Illustrations</li>`,
                    `<li>🎉 Party & Celebration Graphics</li>`,
                    `<li>🐾 Animals and Pets</li>`,
                    `🔥 <b>Perfect for Any Project:</b> Whether you’re creating custom invitations, social media posts, stickers, or scrapbook designs, these clipart files will add a touch of uniqueness and style.`,
                    `🎨 <b>Creative Freedom:</b> Each PNG file can be easily resized, rotated, and layered to match your artistic vision. Compatible with popular software like Photoshop, Canva, and Procreate.`,
                    `📂 <b>Organized for Easy Access:</b> All files are sorted by themes and styles, allowing you to quickly find the perfect element for your project.`,
                    `🚀 <b>Unleash Your Inner Designer:</b> Ideal for small business owners, crafters, and creatives looking for professional-grade assets that are ready to use and easy to customize.`,
                ],
                image: clipart,
            },
            {
                title: '📚 1,000+ Graphics Library 🎨',
                description: `Enhance your projects with this comprehensive Graphics Library, featuring over 1,000 stunning assets to inspire creativity and streamline your design process. Whether you’re a graphic designer, marketer, or DIY creator, this library has everything you need to bring your ideas to life.`,
                details: [
                    `✨ <b>What’s Included:</b>`,
                    `<li><b>Illustrations:</b> Versatile and visually striking graphics for any theme or industry 🖌️.</li>`,
                    `<li><b>Patterns:</b> Seamless designs for backgrounds, packaging, and branding 🌟.</li>`,
                    `<li><b>Textures:</b> Add depth and richness to your designs with high-quality textures 🎨.</li>`,
                    `<li><b>Shapes & Elements:</b> Simple yet impactful components perfect for modern or minimalist designs 🔷</li>`,
                    `🌟 <b>Diverse Applications:</b> <br>From website designs and print materials to digital marketing campaigns and social media posts, this collection is versatile and adaptable for various projects.`,
                    `🔥 <b>Key Features:</b>`,
                    `<li><b>High-Resolution Quality:</b> All assets are crafted to maintain clarity, whether for web or print.</li>`,
                    `<li><b>Fully Editable:</b> Easily customize colors, sizes, and styles using popular design tools like Adobe Illustrator, Photoshop, and Canva.</li>`,
                    `<li><b>Multi-Format Files:</b> Graphics are provided in multiple formats to suit your preferred software.</li>`,
                    `📂 <b>Organized and Ready-to-Use:</b> <br>Assets are neatly categorized into folders by style and purpose, ensuring you can quickly find what you need without hassle.`,
                    `🚀 <b>Perfect for Professionals and Hobbyists Alike:</b> <br>Whether you're designing for a client or creating personal art, this library empowers you to craft standout visuals with ease.`,
                ],
                image: graphiclibrary,
            },
        ],
    },
    {
        header: 'Templates',
        items: [
            {
                title: '📊 Mega Microsoft PowerPoint Templates 🎨',
                description: `Create stunning presentations with ease using the Mega Microsoft PowerPoint Templates Collection. Packed with a vast array of customizable designs, this bundle is perfect for business professionals, educators, marketers, and creatives who want to impress their audience.`,
                details: [
                    `✨ <b>What’s Inside:</b>`,
                    `<li><b>Infographics PowerPoint Templates:</b> Visualize data with beautiful, editable charts, graphs, and diagrams 📈.</li>`,
                    `<li><b>Premium Themes:</b> High-quality, modern templates tailored for corporate, creative, and personal projects 🏢.</li>`,
                    `<li><b>Creative and Cute Themes:</b> Add personality and charm to your slides with whimsical, colorful templates 🖌️.</li>`,
                    `<li><b>Mockup Templates:</b> Showcase designs in realistic and professional mockup slides 📱.</li>`,
                    `<li><b>Icons and GIFs:</b> Access a library of stylish icons and animated GIFs for dynamic and engaging presentations 🌟.</li>`,
                    `🌟 <b>Diverse Applications:</b> <br>This collection includes templates for all purposes:`,
                    `<li>🧑‍💼 <b>Business Proposals</b></li>`,
                    `<li>🎓 <b>Educational Lectures</b></li>`,
                    `<li>📊 <b>Marketing Reports</b></li>`,
                    `<li>💻 <b>Product Launches</b></li>`,
                    `<li>🎉 <b>Creative Portfolios</b></li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li>Fully customizable and editable layouts to fit your content effortlessly.</li>`,
                    `<li>High-resolution designs ensuring clarity on all screens and projectors.</li>`,
                    `<li>Compatible with Microsoft PowerPoint and Google Slides for added flexibility.</li>`,
                    `📂 <b>Organized for Convenience:</b> All templates are sorted into thematic folders like "Modern Templates," "Infographics," "Mockups," and more, making it easy to find exactly what you need.`,
                    `🚀 <b>Present Like a Pro:</b> Whether you’re pitching a new idea, teaching a concept, or presenting a strategy, this collection ensures your slides stand out and leave a lasting impression.`,
                ],
                image: powerpoint,
            },
            {
                title: '📊 5,000+ Infographics Collection 🎨',
                description: `Bring your data to life with the 5,000+ Infographics Collection, a comprehensive set of professionally designed templates that transform complex information into visually stunning and easy-to-understand graphics. Perfect for presentations, reports, marketing campaigns, and educational content.`,
                details: [
                    `✨ <b>What’s Inside:</b>`,
                    `<li><b>Creative Charts & Graphs:</b> Showcase your numbers with customizable bar charts, pie graphs, line charts, and more 📈.</li>`,
                    `<li><b>Timelines:</b> Highlight milestones or project phases with elegant timeline graphics ⏳.</li>`,
                    `<li><b>Maps & Geography Visuals:</b> Share location-based data with detailed and customizable maps 🗺️.</li>`,
                    `<li><b>Business Infographics:</b> Tailored visuals for business plans, workflows, organizational charts, and KPIs 💼.</li>`,
                    `<li><b>Educational Diagrams:</b> Perfect for simplifying academic or professional concepts for training and teaching 🎓.</li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li>Fully editable templates compatible with PowerPoint, Illustrator, Canva, and more.</li>`,
                    `<li>Available in multiple formats (AI, PSD, PPTX, PNG) for maximum flexibility.</li>`,
                    `<li>High-resolution designs optimized for both print and digital use.</li>`,
                    `🌟 <b>Diverse Applications:</b> <br>Ideal for:`,
                    `📊 <b>Marketing Campaigns</b>`,
                    `📖 <b>Educational Content</b>`,
                    `🧑‍💼 <b>Corporate Presentations</b>`,
                    `🌍 <b>Geographical & Statistical Data Representation</b>`,
                    `📂 <b>Organized and Ready-to-Use:</b> <br>All files are sorted by category and theme for quick and easy access, saving you time while maintaining professional results.`,
                    `🚀 <b>Transform Your Presentations:</b> <br>Turn raw data into compelling visuals that capture your audience’s attention and effectively communicate your message.`,
                ],
                image: infographic,
            },
            {
                title: '💍 1,200+ Wedding Album Templates 🎨',
                description: `Celebrate love and create lasting memories with this stunning collection of 1,200+ Wedding Album Templates. Perfect for photographers, designers, and couples looking to capture the beauty of their special day in style.`,
                details: [
                    `✨ <b>What’s Inside:</b>`,
                    `<li><b>Elegant Layouts:</b> Timeless designs to showcase wedding photos with grace and sophistication 🌹.</li>`,
                    `<li><b>Thematic Collections:</b> Templates tailored for various wedding styles, including vintage, rustic, modern, and minimalist 🎨.</li>`,
                    `<li><b>Customizable Elements:</b> Add names, dates, and special messages to make each album uniquely personal 💌.</li>`,
                    `<li><b>High-Resolution Designs:</b> Print-ready templates ensuring crisp, professional-quality results 📸.</li>`,
                    `🌟 <b>Perfect for:</b>`,
                    `<li>📖 <b>Wedding Albums:</b> Create treasured keepsakes for newlyweds.</li>`,
                    `<li>💻 <b>Digital Displays:</b> Share love stories online through digital photo albums.</li>`,
                    `<li>🎁 <b>Personalized Gifts:</b> Perfect for creating thoughtful wedding gifts for family and friends.</li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li>Available in multiple formats, including PSD and AI, for seamless customization.</li>`,
                    `<li>Fully editable layouts to match any wedding theme or aesthetic.</li>`,
                    `<li>Compatible with Adobe Photoshop, Illustrator, Canva, and other design tools.</li>`,
                    `📂 <b>Organized for Convenience:</b> <br>All templates are sorted into categories like "Traditional," "Modern Elegance," and "Minimalist Chic," making it easy to find the perfect design for every couple’s style.`,
                    `🚀 <b>Create Timeless Keepsakes:</b> <br>Whether you’re a professional photographer designing albums for clients or a couple crafting your own memories, these templates help you tell your love story beautifully.`,
                ],
                image: weddingalbum,
            },
            {
                title: '📄 500+ Professional Resume Templates 🎨',
                description: `Stand out in today’s competitive job market with this curated collection of 500+ Resume Templates. Designed to make a lasting impression, these resumes are ideal for job seekers, students, and professionals across various industries.`,
                details: [
                    `✨ <b>What’s Inside:</b>`,
                    `<li><b>Single-Page Resumes:</b> Perfect for concise, impactful presentations of your skills and experience 📝.</li>`,
                    `<li><b>Double-Page Resumes:</b> For detailed professionals who want to highlight extensive work history or accomplishments 📂.</li>`,
                    `<li><b>Cover Pages:</b> Impress employers with matching cover pages that tie your application together beautifully 📄.</li>`,
                    `<li><b>Creative Resume Sets:</b> Unique designs for creative industries like design, media, and marketing 🎨.</li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li><b>Customizable and Editable:</b> Adjust colors, fonts, and layouts effortlessly using tools like Microsoft Word, Adobe Photoshop, or Canva 🎨.</li>`,
                    `<li><b>Modern and Minimalist Designs:</b> Keep it sleek and professional to grab attention instantly 💻.</li>`,
                    `<li><b>High-Resolution Files:</b> Ready for print or digital submission.</li>`,
                    `🌟 <b<Applications:</b> <br>Whether applying for corporate jobs, internships, or freelance opportunities, these templates cater to every career goal:`,
                    `<li>🏢 <b>Corporate Resumes</b></li>`,
                    `<li>🎨 <b>Creative Industry CVs</b></li>`,
                    `<li>📖 <b>Academic Profiles</b></li>`,
                    `📂 <b>Organized for Convenience:</b> <br>Templates are neatly categorized into folders for single-page, double-page, and creative sets, so you can quickly find the perfect match for your application.`,
                    `🚀 <b>Perfect for All Job Seekers:</b> <br>Boost your chances of landing your dream job with a polished, professional, and personalized resume.`,
                ],
                image: resumetemplate,
            },
        ],
    },
    {
        header: 'Graphic Design Assets',
        items: [
            {
                title: '🔤 Mega Fonts Collection 🎨',
                description: `Unleash your creativity with the Mega Fonts Collection, a treasure trove of diverse and high-quality fonts perfect for any design project. Whether you’re crafting a logo, creating a website, or designing invitations, this collection has the perfect font for every occasion.`,
                details: [
                    `✨ <b>What’s Inside:</b>`,
                    `<b>Over 10,000 Fonts:</b> A vast range of fonts in different styles, including:`,
                    `<li>🖋️ <b>Script & Handwritten:</b> Elegant and artistic fonts for personal touches.</li>`,
                    `<li>🏢 <b>Sans-Serif & Modern:</b> Clean and professional fonts for business and branding.</li>`,
                    `<li>🎨 <b>Display & Decorative:</b> Unique fonts to make your designs stand out.</li>`,
                    `<li>📚 <b>Classic Serif:</b> Timeless fonts for editorial and academic designs.</li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li><b>Multi-Format Files:</b> Fonts available in OTF, TTF, and WOFF formats for compatibility with all software.</li>`,
                    `<li><b>High-Quality Designs:</b> Each font is professionally crafted to ensure clarity and style.</li>`,
                    `<li><b>Editable and Scalable:</b> Adjust sizes, colors, and effects effortlessly to suit your needs.</li>`,
                    `🌟 <b>Perfect for All Projects:</b>`,
                    `<li>🎨 <b>Graphic Design:</b> Logos, branding, and digital art.</li>`,
                    `<li>🖋️ <b>Print Media:</b> Posters, flyers, and invitations.</li>`,
                    `<li>💻 <b>Web Design:</b> Websites, UI/UX design, and online stores.</li>`,
                    `<li>📜 <b>Personal Use:</b> Journals, crafts, and DIY projects.</li>`,
                    `📂 <b>Organized and Ready-to-Use:</b> <br>Fonts are neatly categorized into folders (A-Z) for quick browsing and effortless selection.`,
                    `🚀 <b>Bring Your Ideas to Life:</b> <br>Whether you’re a professional designer or a hobbyist, this collection provides the ultimate toolkit for typography and design excellence.`,
                ],
                image: font,
            },
            {
                title: '🎨 15,000+ Photoshop Actions Collection 🎥',
                description: `Save time and elevate your photo editing game with the 15,000+ Photoshop Actions Collection, a treasure trove of pre-designed actions to streamline your creative process. Ideal for photographers, graphic designers, and digital artists, these actions will transform your projects with just a few clicks.`,
                details: [
                    `✨ <b>What’s Inside: <br>Diverse Categories:</b>`,
                    `<li>🌈 <b>Creative Effects:</b> Add unique and eye-catching enhancements to your photos.</li>`,
                    `<li>📸 <b>Photography Enhancements:</b> Includes HDR effects, color corrections, and black-and-white conversions.</li>`,
                    `<li>💍 <b>Specialized Themes:</b> Wedding edits, fashion photography, retro/vintage effects, and more.</li>`,
                    `<li>🖼️ <b>Artistic Filters:</b> Achieve painterly, sketch, and pastel effects.</li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li><b>One-Click Edits:</b> Speed up your workflow with pre-built actions that apply complex edits in seconds.</li>`,
                    `<li><b>Customizable Results:</b> All actions are non-destructive and fully editable, giving you complete control over the final look.</li>`,
                    `<li><b>High-Resolution Quality:</b> Perfect for both digital and print applications.</li>`,
                    `<li><b>Compatible with All Photoshop Versions:</b> Works seamlessly with Adobe Photoshop CS3 and newer versions.</li>`,
                    `🌟 <b>Applications:</b> <br>Perfect for:`,
                    `<li>📷 <b>Photo Editing:</b> Improve clarity, depth, and vibrancy with professional-grade filters.</li>`,
                    `<li>📖 <b>Digital Art Creation:</b> Add texture, tone, and special effects for creative projects.</li>`,
                    `<li>📊 <b>Graphic Design Projects:</b> Enhance promotional materials, websites, and social media content.</li>`,
                    `📂 <b>Organized for Easy Access:</b> <br>All actions are categorized into thematic folders like "Cinematic Effects," "Light Leaks," "Seasonal Edits," and more, ensuring you can find the perfect action for every project.`,
                    `🚀 <b>Enhance Your Workflow:</b> <br>Whether you’re retouching portraits or creating bold graphic designs, this collection provides the tools to achieve professional results efficiently.`,
                ],
                image: photoshopaction,
            },
            {
                title: '🎨 5,000 Adobe Illustrator Templates 🖌️',
                description: `Unlock unlimited creative potential with this vast collection of 5,000 Adobe Illustrator Templates, designed to make your projects stand out. Perfect for designers, marketers, and business owners, these templates are highly customizable and versatile for any purpose.`,
                details: [
                    `✨ <b>What’s Inside:</b>`,
                    `<li><b>Logo Templates:</b> Create stunning brand identities with professional logo designs 🌟.</li>`,
                    `<li><b>Flyers & Brochures:</b> Perfect for marketing campaigns, events, and promotions 📄.</li>`,
                    `<li><b>Business Cards:</b> Leave a lasting impression with modern, clean, and creative designs 💼.</li>`,
                    `<li><b>Posters & Banners:</b> Eye-catching designs to elevate advertising efforts 📢.</li>`,
                    `<li><b>Infographics:</b> Simplify complex data with visually appealing diagrams and charts 📊.</li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li><b>High-Quality Files:</b> All templates are crafted in high resolution, suitable for both digital and print use.</li>`,
                    `<li><b>Fully Editable:</b> Customize colors, text, and layouts in Adobe Illustrator and other vector-based software.</li>`,
                    `<li><b>Multiple Formats:</b> Files provided in .AI and .EPS formats, ensuring compatibility across tools.</li>`,
                    `<li><b>Professional Design Standards:</b> Ensure your work stands out with polished, creative templates.</li>`,
                    `🌟 <b>Applications:</b> <br>Perfect for:`,
                    `<li>🎨 <b>Graphic Design Projects:</b> Logos, branding, and marketing materials.</li>`,
                    `<li>🖋️ <b>Print Media:</b> Flyers, brochures, and posters.</li>`,
                    `<li>💻 <b>Digital Content:</b> Social media graphics, web assets, and presentations.</li>`,
                    `📂 <b>Organized for Efficiency:</b> <br>Templates are sorted into categories like "Corporate," "Creative," "Minimalist," and more, making it easy to locate the perfect design for any project.`,
                    `🚀 <b>For Professionals and Beginners Alike:</b> <br>Whether you’re an experienced designer or just starting out, this collection empowers you to create exceptional designs effortlessly.`,
                ],
                image: aitemplate,
            },
            {
                title: '🎨 5,000 CorelDraw Templates 🖌️',
                description: `Maximize your design capabilities with the 5,000 CorelDraw Templates Collection, a versatile library of professional-grade templates designed to make your projects shine. Whether you're designing for print or digital, this collection offers endless possibilities.`,
                details: [
                    `✨ <b>What’s Inside:</b>`,
                    `<li><b>Business Essentials:</b> 🏢 Logos, business cards, brochures, and letterheads for professional branding.</li>`,
                    `<li><b>Creative Designs:</b> 🎸 T-shirt prints, team art, and rock band themes for artistic projects.</li>`,
                    `<li><b>Events & Promotions:</b> 🎉 Flyers, posters, and banners for events, promotions, and advertisements.</li>`,
                    `<li><b>Specialized Vectors:</b> 🌟 Silhouettes, superhero art, and unique vector illustrations for niche projects.</li>`,
                    `<li><b>Seasonal Prints:</b> 🍂 Themes for holidays, sports events, and other seasonal occasions.</li>`,
                    `🔥 <b>Key Features:</b>`,
                    `<li><b>High-Quality Files:</b> Crafted for print and digital applications.</li>`,
                    `<li><b>Fully Customizable:</b> Editable in CorelDraw, allowing for seamless modifications to fit your needs.</li>`,
                    `<li><b>Multi-Purpose Applications:</b> Perfect for branding, marketing, and artistic ventures.</li>`,
                    `🌟 <b>Ideal For:</b>`,
                    `<li>🖋️ <b>Graphic Designers:</b> Create stunning visuals and layouts with ease.</li>`,
                    `<li>📢 <b>Marketing Teams:</b> Develop promotional materials that grab attention.</li>`,
                    `<li>🎁 <b>Crafters & Creators:</b> Customize designs for merchandise, gifts, and more.</li>`,
                    `📂 <b>Organized and Easy to Use:</b> <br>All templates are neatly categorized for quick access, making it effortless to find the perfect design for your project.`,
                    `🚀 <b>Why Choose This Collection?</b> <br>Whether you're a professional or a hobbyist, this collection empowers you to create polished and impactful designs in record time.`,
                ],
                image: coreldraw,
            },
        ],
    },
];

const CategorySection = ({ refs }) => {
    return (
        <section className="py-16 bg-gray-200 text-gray-900">
            <div className="container mx-auto px-4">
                <SectionHeader
                    title="Explore Our Products"
                    customClass="text-center text-3xl font-bold uppercase mb-10 tracking-wide"
                    subtitle="Hot off the Press"
                    subtitleClass="text-yellow-600 text-lg font-semibold mb-10"
                />
                {categories.map((category, categoryIndex) => (
                    <div key={categoryIndex} className="mb-16">
                        <div className="relative mb-8 md:-mx-48 -mx-4 px-4">
                            <div className="absolute inset-0 bg-gradient-to-r from-gray-500 via-gray-950 to-gray-500 h-40 md:h-60 w-full"></div>
                            <h2 className="relative z-10 text-white text-2xl md:text-4xl font-bold text-center py-14 md:py-20 md:pt-20">
                                {category.header}
                            </h2>
                        </div>
                        <div className="-mt-16 md:-mt-20 grid grid-cols-1 md:grid-cols-2 gap-8 relative z-10">
                            {category.items.map((item, itemIndex) => (
                                <div
                                    key={itemIndex}
                                    className="flex flex-col bg-white shadow-xl rounded-lg overflow-hidden hover:shadow-2xl transition-all duration-300"
                                    ref={refs[item.title]} // Attach the corresponding ref
                                >
                                    <div className="flex justify-center items-center md:pt-20 pt-8 pb-4 px-4">
                                        <div className="w-[360px] h-[220px] md:w-[440px] md:h-[280px] bg-gray-100 rounded-lg overflow-hidden shadow-2xl">
                                            {item.video ? (
                                                <video
                                                    src={item.video}
                                                    controls
                                                    className="w-full h-full object-cover"
                                                ></video>
                                            ) : (
                                                <img
                                                    src={item.image}
                                                    alt={item.title}
                                                    className="w-full h-full object-cover"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h3 className="text-2xl font-bold text-gray-800 mb-4">
                                            {item.title}
                                        </h3>
                                        <p className="text-base leading-7 text-gray-900 mb-4">
                                            {item.description}
                                        </p>
                                        {item.details.map((detail, detailIndex) => (
                                            <p
                                                key={detailIndex}
                                                className="text-base leading-6 text-gray-600 mb-4"
                                                dangerouslySetInnerHTML={{ __html: detail }}
                                            ></p>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default CategorySection;
