// src/components/MegaMenu.jsx
import React from 'react';

const MegaMenu = ({ onScroll }) => {
    // Categorized menu items
    const categories = [
        {
            category: '🎥 Video and Motion Graphics',
            items: [
                'Professional Overlays',
                'Final Cut Pro',
                'Motion Graphics',
                'Adobe Premiere Pro',
                'Adobe After Effects',
                'Premiere Pro Presets',
            ],
        },
        {
            category: '🎨 Graphic Elements',
            items: [
                'Ultimate T-Shirt Design',
                'Ultimate SVG Elements',
                'Premium Mug Design',
                'Icons Collection',
                'PNG Clipart',
                'Graphics Library',
            ],
        },
        {
            category: '📝 Templates',
            items: [
                'PowerPoint Templates',
                'Infographics',
                'Wedding Album',
                'Resume Templates',
            ],
        },
        {
            category: '🖌️ Graphic Design Assets',
            items: [
                'Fonts Collection',
                'Photoshop Action',
                'Adobe Illustrator Templates',
                'CorelDraw Templates',
            ],
        },
    ];

    return (
        <div className="absolute bg-white text-gray-900 rounded shadow-lg mt-2 py-4 w-[700px] transition-opacity duration-200">
            <div className="grid grid-cols-2 gap-8 px-6">
                {categories.map((category, categoryIndex) => (
                    <div key={categoryIndex}>
                        {/* Category Header */}
                        <h3 className="text-lg font-semibold text-gray-700 mb-4">
                            {category.category}
                        </h3>
                        {/* Items */}
                        <ul className="space-y-2">
                            {category.items.map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <button
                                        className="text-sm text-gray-600 hover:text-blue-600 transition-colors"
                                        onClick={() => onScroll(item)}
                                    >
                                        {item}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MegaMenu;
